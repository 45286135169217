import { TriangleAlert } from "lucide-react";
import { Button } from "../ui/button";
import { Link } from "react-router-dom";
import { ReloadIcon } from "@radix-ui/react-icons";
import { Card, CardContent, CardHeader } from "../ui/card";

interface ErrorBoundaryAlert {
  customTitle?: string;
  customMessage?: string;
  allowRetry?: boolean;
}

export function ErrorBoundaryAlert({ customMessage, customTitle, allowRetry = true }: ErrorBoundaryAlert) {
  return (
    <Card className="w-full h-full flex-1">
      <CardHeader />
      <CardContent className="flex items-center justify-center flex-col gap-4">
        <span className="flex items-center justify-center bg-red-100 w-20 h-20 rounded-full">
          <TriangleAlert className="text-red-500 w-12 h-12" />
        </span>

        <div className="text-center max-w-96">
          <strong className="text-base">{customTitle ? customTitle : 'Ocorreu um erro ao tentar carregar a página'}</strong>
          <p className="text-sm text-muted-foreground">{customMessage ? customMessage : 'Recarregue a página e tente novamente'}</p>

          {allowRetry && (
            <div className="flex gap-2  my-4">
              <Button className="space-x-2 w-full lg:max-w-48 m-auto" variant="default" onClick={() => window.location.reload()}>
                <ReloadIcon className="w-3 h-3" />
                <span>Recarregar página</span>
              </Button>
            </div>
          )}
        </div>

        <p className="text-xs mt-5 text-center text-muted-foreground">Se o erro persistir entre em contato com o <Link to="#" className="underline dark:text-blue-400 text-blue-600">suporte</Link></p>
      </CardContent>
    </Card>
  )
}