import '../src/app/libs/sentry'
import '../src/app/libs/viteErrorHandler'

import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from "./app/libs/queryClient"

import './global.css'
import { Router } from "./app/router"
import { AuthProvider } from "./app/contexts/AuthContext"
import { ModalErrorProvider } from "./app/contexts/ErrorModalContext"
import { BrowserRouter } from "react-router-dom"
import { ThemeProvider } from "./app/contexts/ThemeContext"

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import {Toaster as ReactHotToaster} from 'react-hot-toast'
import { Toaster } from './view/components/ui/sonner'

function App() {
  return (
    <>
      <Toaster />
      <ReactHotToaster/>
      <ModalErrorProvider />
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <BrowserRouter>
          <AuthProvider>
            <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
              <Router />
            </ThemeProvider>
          </AuthProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </>
  )
}

export default App
