import { create } from 'zustand'

type State = {
  userId: number | undefined;
  storeId: number | undefined;
}

type Action = {
  handleSetUser(userId:number | undefined, storeId:number | undefined): void;
}

export const useUser = create<State & Action>((set) => ({
  userId: 0,
  storeId: 0,
  handleSetUser: (link, sublink) => set(() => ({ userId: link, storeId: sublink})),
}));
